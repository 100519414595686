.footer {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(220, 220, 220, 1) 100%);
    color: #333;
    padding: 40px 20px;
    text-align: center;
    font-family: Arial, sans-serif;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-section {
    flex: 1;
    margin: 20px;
}

.footer-section h3 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
    display: inline-block;
}

.footer-section p, .footer-section ul {
    font-size: 14px;
    line-height: 1.6;
    color: #555;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #555;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section ul li a:hover {
    color: #333;
}

.footer-section .social-icons {
    margin-top: 20px;
}

.footer-section .social-icons a {
    color: #555;
    font-size: 20px;
    margin: 0 10px;
    transition: color 0.3s ease;
}

.footer-section .social-icons a:hover {
    color: #333;
}

.footer-bottom {
    margin-top: 30px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
    font-size: 14px;
}

.footer-bottom p {
    margin: 0;
    color: #555;
}


.footer-section.contact {
    text-align: center;
    margin: 0 auto; 
}

.footer-section.contact p {
    margin: 5px 0;
}

.footer-section .social-icons {
    justify-content: center;
    display: flex;
}
.social-icons {
    display: flex;
    justify-content: center;
    gap: 20px; 
    margin-top: 20px;
  }
  
  .social-icons a {
    color: #555; 
    font-size: 24px; 
    text-decoration: none;
    transition: transform 0.3s ease, color 0.3s ease; 
  }
  
  .social-icons a:hover {
    color: #333; 
    transform: scale(1.2); 
  }

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
    }

    .footer-section {
        margin: 10px 0;
    }

    .footer-section.contact {
        margin-bottom: 20px; 
    }
    .social-icons {
        gap: 15px; 
      }
    
      .social-icons a {
        font-size: 22px; 
      }
}
@media (max-width: 480px) {
    .social-icons a {
      font-size: 20px; 
    }
  }