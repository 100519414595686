.booking-container {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.note {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.features {
  list-style: none;
  padding: 0;
  margin-bottom: 15px;
}

.features li {
  margin-bottom: 5px;
  font-size: 14px;
}

.villa-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
  background-color: #ff8965;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
}

.villa-options {
  display: grid;
  gap: 15px;
  margin-top: 10px;
}

.villa-option {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
  padding: 15px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.original-price {
  text-decoration: line-through;
  color: #999;
  margin-right: 5px;
}

.discounted-price {
  color: green;
  font-weight: bold;
}

.taxes {
  font-size: 12px;
  color: #666;
}

.breakfast,
.cancellation,
.select-villa select {
  text-align: center;
}

.contact-information {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.contact-information h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.reserve-button {
  width: 100%;
  padding: 12px;
  background-color: #ff8965;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reserve-button:hover {
  background-color: #e65a33;
}

.confirmation {
  margin-top: 10px;
  color: green;
  font-size: 14px;
  text-align: center;
}
/* .amenities-section */
.booking-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.villa-description,
.amenities-section {
  /* background-color: #fff; */
  padding: 20px;
  border-radius: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.villa-description {
  grid-column: 1 / 2;
}

.amenities-section {
  grid-column: 2 / 3;
}

.amenities-section h2,
.villa-description h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
}

.features,
.amenities-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.features li,
.amenities-list li {
  /* margin-bottom: 5px; */
  font-size: 14px;
}

.amenities-section {
  /* background-color: #fff; */
  padding: 20px;
  border-radius: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.amenities-section h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
}

.amenities-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.amenities-list li {
  display: flex;
  align-items: center;
  /* background-color: #f9f9f9; */
  /* padding: 10px; */
  /* border-radius: 5px; */
  font-size: 12px;
  color: #333;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
}

.amenities-list li::before {
  content: "✔";
  color: green;
  margin-right: 8px;
  font-weight: bold;
}
.book-your-stay {
  max-width: 455px;
  margin: 20px auto;
  padding: 20px;
  /* background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  text-align: center;
}

.book-your-stay h3 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.date-picker-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 15px;
}

.date-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.select-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.select-container div {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.select-container label {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
}

.select-container select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .booking-content {
    grid-template-columns: 1fr;
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .villa-header,
  .villa-option {
    grid-template-columns: 1fr;
  }

  .booking-container {
    padding: 10px;
  }

  .reserve-button {
    font-size: 14px;
  }

  .contact-information {
    max-width: 100%;
    padding: 15px;
  }
  .amenities-list {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 20px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 12px;
  }

  .reserve-button {
    font-size: 14px;
  }
  .amenities-list li {
    font-size: 12px;
  }
}
