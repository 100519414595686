.above-header {
  display: flex;
  align-items: center;
  background: #d2cccc;
  gap: 10px;
  color: black;
  padding: 10px 20px;
  text-align: center;
  font-size: 0.9rem;
}
a {
  text-decoration: none !important;
  color: black !important;
}
