.contact-container {
  padding: 50px;
  text-align: center;
  background-color: #f9f9f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-content {
  max-width: 600px; /* Restricting width for better readability */
  margin: 0 auto;
  background-color: #fff; /* White background for the form */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for elevation */
  animation: fadeIn 1.5s ease-in-out;
}

.contact-content h1 {
  font-size: 36px;
  color: #ff7e5f;
  margin-bottom: 20px;
}

.contact-content p {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 30px;
}

.contact-form {
  /* display: flex; */
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
  font-weight: 600;
}

input,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px; /* Rounded corners for input fields */
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input:focus,
textarea:focus {
  border-color: #ff7e5f; /* Highlight border on focus */
  outline: none; /* Remove default outline */
}

textarea {
  resize: vertical; /* Allow vertical resizing */
}

.submit-button {
  margin-top: 20px;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  background-color: #ff7e5f;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
  background-color: #e06f5a;
}

.submit-button:active {
  transform: scale(0.98); /* Slightly shrink the button on click */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .contact-container {
    padding: 30px 15px; /* Adjust padding for smaller screens */
  }

  .contact-content {
    padding: 15px; /* Adjust padding inside form for smaller screens */
  }

  .contact-content h1 {
    font-size: 28px; /* Adjust font size for smaller screens */
  }

  .contact-content p {
    font-size: 16px; /* Adjust font size for smaller screens */
    margin-bottom: 20px; /* Reduce margin for better spacing */
  }

  .submit-button {
    padding: 10px 18px; /* Adjust button size for smaller screens */
    font-size: 16px;
  }
}
