.about-container {
  padding: 50px;
  text-align: center;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
  animation: fadeIn 1.5s ease-in-out;
}

.about-content h1 {
  font-size: 36px;
  color: #ff7e5f;
  margin-bottom: 20px;
}

.about-content p {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 30px;
}

.gallery-container {
  max-width: 1000px;
  margin: 0 auto;
}

.gallery-container h2 {
  font-size: 28px;
  color: #ff7e5f;
  margin-bottom: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.gallery-image {
  width: 100%;
  height: 90%;
  border-radius: 8px; /* Rounded corners for images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for images */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.05); /* Zoom effect on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .about-container {
    padding: 30px 15px; /* Adjust padding for smaller screens */
  }

  .about-content h1 {
    font-size: 28px; /* Adjust font size for smaller screens */
  }

  .about-content p {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .gallery-container {
    max-width: 100%; /* Full width on smaller screens */
    padding: 0 15px; /* Add padding for smaller screens */
  }

  .gallery-grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(150px, 1fr)
    ); /* Adjust grid layout */
    gap: 10px; /* Reduce gap between images */
  }

  .gallery-image {
    border-radius: 5px; /* Slightly smaller rounded corners */
  }
}

@media (min-width: 769px) {
  .about-container {
    padding: 50px; /* Keep padding for larger screens */
  }

  .about-content h1 {
    font-size: 36px; /* Keep font size for larger screens */
  }

  .about-content p {
    font-size: 18px; /* Keep font size for larger screens */
  }

  .gallery-container {
    max-width: 1000px; /* Maintain max width for larger screens */
  }

  .gallery-grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(250px, 1fr)
    ); /* Maintain grid layout */
  }
}
