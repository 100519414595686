.about-container {
  padding: 50px;
  text-align: center;
  background-color: #f9f9f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-content {
  margin-bottom: 40px;
  max-width: 800px;
  animation: fadeIn 2s ease-in-out;
}

.about-content h1 {
  font-size: 36px;
  color: #ff7e5f;
  margin-bottom: 20px;
}

.about-content p {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
}

.about-content ul {
  list-style-type: disc;
  text-align: left;
  margin: 0 auto;
  padding-left: 20px;
  max-width: 500px;
}

.about-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  animation: slideIn 1.5s ease-in-out;
  flex-wrap: wrap;
}

.image {
  width: 250px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(30px);
  animation: imageFadeIn 1s forwards;
}

.image1 {
  animation-delay: 0.3s;
}

.image2 {
  animation-delay: 0.6s;
}

.image3 {
  animation-delay: 0.9s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes imageFadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .about-container {
    padding: 30px 15px; /* Adjust padding for smaller screens */
  }

  .about-content h1 {
    font-size: 28px; /* Adjust font size for smaller screens */
  }

  .about-content p {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .about-content ul {
    padding-left: 10px; /* Reduce padding for smaller screens */
  }

  .about-images {
    gap: 10px; /* Reduce gap between images */
  }

  .image {
    width: 100%; /* Full width for smaller screens */
    height: auto; /* Maintain aspect ratio */
  }
}
