.activities-section {
    padding: 40px;
    background-color: #fff;
    text-align: center;
}

.activities-section h2 {
    color: #ff7e5f;
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: 700;
}

.activities-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}


.activity {
    width: 200px; 
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
}

.activity img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 15px;
}

.activity h3 {
    color: #333;
    font-size: 20px;
    margin-bottom: 10px;
}

.activity p {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
}
.activity-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 0 auto; 
    max-width: 1200px; 
    padding: 20px;
    text-align: justify; 
}

@media (max-width: 768px) {
 
    .activities-section {
        padding: 20px;
    }
    .activity-header {
        flex-direction: column; 
        text-align: left; 
        margin-left: 10px;
        margin-right: 10px;
    }
  
    .activities-section h2 {
        font-size: 28px;
    }

   
    .activities-container {
        flex-direction: column; 
        align-items: center;
    }

    
    .activity {
        width: 90%; 
        margin-bottom: 20px; 
    }

    .activity h3 {
        font-size: 18px;
    }

   
    .activity p {
        font-size: 14px;
    }
    .activity-header p{
        font-size: 14px;
    }
}
